<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    address: string
    city: string
    distanceFromUser?: number
    restaurantCode?: string
    showIfFavorite?: boolean
    smallFont?: boolean
  }>(),
  {
    distanceFromUser: undefined,
    restaurantCode: undefined,
    showIfFavorite: undefined,
    smallFont: false,
  }
)

const authStore = useAuthStore()
const restaurantStore = useRestaurantStore()
const { isFavorite, toggleFavorite } = useFavoriteRestaurant(props.restaurantCode)

const addressStr = computed(() => {
  if (!props.address) return undefined
  return `${props.address},`
})

const cityStr = computed(() => props.city || undefined)

const distanceStr = computed(() => {
  return distanceToString(props.distanceFromUser)
})
</script>

<template>
  <div class="flex justify-between tracking-[-0.15]" :class="smallFont ? 'text-2xs' : 'text-xs'">
    <div
      class="flex w-fit flex-col flex-wrap justify-between"
      :class="{
        'max-w-[75%]': distanceFromUser,
      }"
    >
      <p v-if="addressStr">{{ addressStr }}</p>
      <p v-if="cityStr">{{ cityStr }}</p>
    </div>
    <div
      v-if="restaurantStore.isGeoLocationActive && distanceFromUser"
      class="flex h-fit items-center self-end"
    >
      <img src="/images/icons/distance.svg" class="h-4 fill-mcd-secondaryDarkGrey" alt="distance" />
      <p class="text-mcd-secondaryGrey">{{ distanceStr }}</p>
    </div>
    <img
      v-if="showIfFavorite && authStore.isUserLogged"
      class="cursor-pointer"
      :src="
        isFavorite
          ? '/images/icons/favoriteFilled.svg'
          : '/images/icons/profileSections/favorite.svg'
      "
      alt="favorite icon"
      @click="toggleFavorite"
    />
  </div>
</template>
