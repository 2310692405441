<script setup lang="ts">
import { DateTime } from 'luxon'
import {
  RestaurantLiteWithDistance,
  RestaurantScheduleTypesUnion,
  RestaurantScheduleTypes,
} from '~/lib/interfaces/restaurant'

const props = defineProps<{
  restaurant: RestaurantLiteWithDistance
}>()

const { t } = useLocale()
const appStore = useAppStore()

const stateText = ref<RestaurantScheduleTypesUnion>()

const state = getRestaurantOpenState({
  datetime: DateTime.utc(),
  active: props.restaurant.active,
  timezone: props.restaurant.timezone,
  code: props.restaurant.code,
  timePeriods: props.restaurant.timeSlotsService,
  pickupTime: appStore.ecommerceConfig?.pickupTime || 0,
})

stateText.value = getRestaurantStateMop(state)
</script>

<template>
  <p
    v-if="stateText === RestaurantScheduleTypes.CLOSED"
    class="mt-1 text-2xs font-bold text-mcd-lightRed"
  >
    {{ t('restaurant.status.closed') }}
  </p>
  <p
    v-if="stateText === RestaurantScheduleTypes.NEAR_TO_CLOSE"
    class="mt-1 text-2xs font-bold text-mcd-tertiaryBlue"
  >
    {{ t('restaurant.status.nearToClose') }}
  </p>

  <p
    v-if="stateText === RestaurantScheduleTypes.ONLY_LOCAL"
    class="mt-1 text-2xs font-bold text-mcd-tertiaryBlue"
  >
    {{ t('restaurant.status.onlyLocal') }}
  </p>
</template>
