import { distanceBetweenCoordinates } from '~/lib/geoUtils'

export function useUserLocation() {
  const restaurantStore = useRestaurantStore()

  const userCoordinates = ref<
    | {
        lat: number
        lng: number
      }
    | undefined
  >()

  const distanceFromUser = (restaurantPosition: { lat: number; lng: number }) => {
    if (!userCoordinates.value) return 0
    return distanceBetweenCoordinates(
      userCoordinates.value.lat,
      userCoordinates.value.lng,
      restaurantPosition.lat,
      restaurantPosition.lng
    )
  }

  onMounted(async () => {
    if (restaurantStore.isGeoLocationActive) {
      const userLocation = await getUserLocation()
      userCoordinates.value = userLocation
    }
  })

  return { distanceFromUser, userCoordinates }
}
